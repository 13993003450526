<template>
  <div class="container">
    <el-card class="password" shadow="hover">
      <div slot="header">修改密码</div>
      <el-form label-width="100px" :model="form" ref="form" :rules="rules" style="padding-top: 20px;">
        <el-form-item label="旧密码" prop="old_password">
          <el-input placeholder="请输入旧密码" type="password" v-model="form.old_password" />
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input placeholder="请输入新密码" type="password" v-model="form.password" />
        </el-form-item>
        <el-form-item label="确认密码" prop="password_confirmation">
          <el-input placeholder="请输入确认密码" type="password" v-model="form.password_confirmation" />
        </el-form-item>
        <el-form-item >
          <el-button type="primary" :loading="submitting" @click="submitHandle">确定</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { password } from '@/request/modules/user'

export default {
  data () {
    let rePwdCheck = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('确认密码与新密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: ''
      },
      rules: {
        old_password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        password_confirmation: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: rePwdCheck, trigger: 'blur' }
        ]
      },
      submitting: false
    }
  },
  methods: {
    submitHandle () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let form = this.form
          this.submitting = true
          password(form).then(resp => {
            this.submitting = false
            this.$message.success('修改密码成功')
            this.$router.push('/')
          }).catch(() => {
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
